import React from "react"
import { Helmet } from "react-helmet";
import Layout from "@Components/Layout/layout"
import SEO from "@Components/SEO/seo"
import { graphql } from 'gatsby'
import { useMatch } from "@reach/router"
import PageModules from '@Components/modules';
import BlogDetails from '@Components/BlogDetails/BlogDetails'
import OfficeDetails from '@Components/OfficeDetails/OfficeDetails'
import TeamDetails from '@Components/TeamDetails/TeamDetails'
import { NEWS_PAGE_URL, CONTACT_PAGE_URL, TEAM_PAGE_URL, CAREERS_PAGE_URL } from "@Components/common/site/constants";
const AreaguideTemplate = (props) => {  
  const Modules = props.data.glstrapi?.area?.Modules
  const Page = props.data.glstrapi?.area
 
  return (
    <Layout popularSearch={'Common'} menutype="menu-transparent" header={true} Modules={Modules ? Modules : ""} 
    // contact={contact}
    >
      <SEO title={Page ? Page?.Name : ''} description={Page ? 'Here is the local guide to '+Page?.Name+' property, know more about living in '+Page?.Name+'. Contact College and County team today for more information.' : ''}/>
      <Helmet
        bodyAttributes={{
          class: `templates-default-template alias-${Page ? Page?.Alias : ''} homepage ${Page ? Page?.Custom_CSS_Class : ''}`
        }}
      />
      <div className="area-detail">
      {Page ?
      <PageModules
      areaguidepage={true}
        Modules={Modules}
        Page={Page}
        // contact={contact}
      />
      : null}
      </div>
    </Layout>
  )
}

export default AreaguideTemplate


export const pageQuery = graphql`
  query AreaQuery($id: ID!) {
    glstrapi {
        area(id: $id, publicationState: LIVE) {
        Name
        Latitude
        Longitude
        Banner_Content
        Banner_Title
        Show_Review
        id
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
        Modules {
          ... on GLSTRAPI_ComponentModuleModules {
            id
            Select_Module
          }
          ... on GLSTRAPI_ComponentModuleCollections {
            id
            Select_Collection
            Content
            Sub_Title
            Title
          }
          ... on GLSTRAPI_ComponentModuleSectionBlock {
            id
            Add_Section {
              Content
              Title
              Video_Url
              Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP
                      width: 1500
                      quality: 75
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )
                  }
                }
              }
              Cta_Label
              Cta_Url {
                Alias
              }
            }
          }
          ... on GLSTRAPI_ComponentModuleServicesCta {
            __typename
            id
            Content
          }
        }
        CTA_Links {
          CTA_Btn_Label1
          CTA_Btn_Label2
          CTA_Url1 {
            URL
            Alias
          }
          CTA_Url2 {
            URL
            Alias
          }
        }
        Banner_Image {
          alternativeText
          url
        }
        }
    }
  }
`
